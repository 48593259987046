* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  border: 0;
}
*,
input,
button,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
}

html {
  height: 100%;
  width: 100%;
  & body {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }

  & #root {
    width: 100%;
    height: 100%;
  }
}
